<template>
  <div class="popup_wrap" style="width:750px; height:500px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- container_ekmtc -->
      <h1 class="page_title">DEM/DET {{ $t('msg.MYIN050T010.071') }}</h1>
      <div class="content_box">
        <div>
          <!-- * {{ $t('msg.CSBL400.019') }} -->
          * {{ $t('msg.MYIN050T010.078') }}<br>   <!-- 현재 승인대기 중인 요청건은 아래와 같습니다. -->
          &nbsp;&nbsp;&nbsp;{{ $t('msg.MYIN050T010.079') }}  <!-- 등록을 진행하시겠습니까? -->
        </div>

        <table class="tbl_col">
          <col style="width:20%" />
          <col style="width:10%" />
          <col style="width:15%" />
          <col style="width:15%" />
          <col style="width:15%" />
          <col style="width:15%" />
          <tr>
            <th rowspan="2" colspan="1">Kind</th>
            <th rowspan="2" colspan="1">Type</th>
            <th rowspan="2" colspan="1">L/D</th>
            <th colspan="3" rowspan="1">Request</th>
          </tr>
          <tr>
            <th>Day</th>
            <th>User</th>
            <th>Country</th>
          </tr>
          <tbody>
            <tr v-for="(item, idx) in reqDupList" :key="'tr' + idx">
              <td align="center">
                {{ item.demDetCatCd === 'Demurrage'? 'DEM'
                  : item.demDetCatCd === 'Detention'? 'DET'
                    : item.demDetCatCd === 'DET(Combined)'? 'COMB'
                      : item.demDetCatCd === 'Monitoring'? 'MOR' : 'OFC' }}
              </td>
              <td align="center">{{ item.cntrTypCd }}</td>
              <td align="center">{{ item.ldCatCd }}</td>
              <td align="center">{{ item.reqDds }}</td>
              <td align="center">{{ item.reqUno }}</td>
              <td align="center">{{ item.reqCtr }}</td>
            </tr>
          </tbody>
        </table>
        <br />
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" @click="$emit('dupOk', 'Y')">{{ this.$t('msg.SETT060P040.007') }}</a> <!-- 신청 -->
        <a class="button gray lg" @click="$emit('close')">{{ this.$t('msg.SETT060P040.008') }}</a> <!-- 취소 -->
      </div>
    </div>
  </div>
</template>

<script>
import FreeTimeExtensReq from '@/api/rest/settle/freeTimeExtensReq'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ContainerCheckPop from '@/pages/settle/ContainerCheckPop'

export default {
  name: 'FreeTimeExtensReq',
  components: {
      WinLayerPop,
      ContainerCheckPop,
      FreeTimeExtensReq
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          dupList: []
        }
      }
    }
  },
  data: function () {
    return {
      getDupList: this.parentInfo.dupList,
      reqDupList: [],
      dupStr: ''
    }
  },
  computed: {
    //this.demDetSelectCheck()
  },
  watch: {
  },
  created () {
    // console.log('this.parentInfo.dupList :::: ', this.parentInfo.dupList)
    this.searchDupData()
  },
  mounted: function () {

  },
  // beforeUpdated () {
  //   this.searchDupData()
  // },
  methods: {
    searchDupData () {
      let dStr = ''
      let dupList = this.parentInfo.dupList

      for (var i = 0; i < dupList.length; i++) {
        dStr += dupList[i].blNo + ',' + dupList[i].cntrTypCd + ',' + dupList[i].demDetCatCd + ',' + dupList[i].eiCatCd + ',' + dupList[i].ldCatCd + ':'
      }

      // console.log('dStr :::: ', dStr)
      this.dupStr = dStr
      FreeTimeExtensReq.findFreetimeDupList(this.dupStr).then(response => {
        console.log('response :::: ', response)
        // console.log('response :::: ', JSON.stringify(response))

        this.reqDupList = response.data.dupList
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
</style>
