var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "750px", height: "500px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v("DEM/DET " + _vm._s(_vm.$t("msg.MYIN050T010.071"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("div", [
            _vm._v(" * " + _vm._s(_vm.$t("msg.MYIN050T010.078"))),
            _c("br"),
            _vm._v(" "),
            _vm._v("    " + _vm._s(_vm.$t("msg.MYIN050T010.079")) + " "),
          ]),
          _c("table", { staticClass: "tbl_col" }, [
            _c("col", { staticStyle: { width: "20%" } }),
            _c("col", { staticStyle: { width: "10%" } }),
            _c("col", { staticStyle: { width: "15%" } }),
            _c("col", { staticStyle: { width: "15%" } }),
            _c("col", { staticStyle: { width: "15%" } }),
            _c("col", { staticStyle: { width: "15%" } }),
            _vm._m(0),
            _vm._m(1),
            _c(
              "tbody",
              _vm._l(_vm.reqDupList, function (item, idx) {
                return _c("tr", { key: "tr" + idx }, [
                  _c("td", { attrs: { align: "center" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.demDetCatCd === "Demurrage"
                            ? "DEM"
                            : item.demDetCatCd === "Detention"
                            ? "DET"
                            : item.demDetCatCd === "DET(Combined)"
                            ? "COMB"
                            : item.demDetCatCd === "Monitoring"
                            ? "MOR"
                            : "OFC"
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { attrs: { align: "center" } }, [
                    _vm._v(_vm._s(item.cntrTypCd)),
                  ]),
                  _c("td", { attrs: { align: "center" } }, [
                    _vm._v(_vm._s(item.ldCatCd)),
                  ]),
                  _c("td", { attrs: { align: "center" } }, [
                    _vm._v(_vm._s(item.reqDds)),
                  ]),
                  _c("td", { attrs: { align: "center" } }, [
                    _vm._v(_vm._s(item.reqUno)),
                  ]),
                  _c("td", { attrs: { align: "center" } }, [
                    _vm._v(_vm._s(item.reqCtr)),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c("br"),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("dupOk", "Y")
                },
              },
            },
            [_vm._v(_vm._s(this.$t("msg.SETT060P040.007")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(this.$t("msg.SETT060P040.008")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { rowspan: "2", colspan: "1" } }, [_vm._v("Kind")]),
      _c("th", { attrs: { rowspan: "2", colspan: "1" } }, [_vm._v("Type")]),
      _c("th", { attrs: { rowspan: "2", colspan: "1" } }, [_vm._v("L/D")]),
      _c("th", { attrs: { colspan: "3", rowspan: "1" } }, [_vm._v("Request")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Day")]),
      _c("th", [_vm._v("User")]),
      _c("th", [_vm._v("Country")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }