var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "750px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("tit.CSBL400.001"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.SETT060P040.001"))),
          ]),
          _vm._v(" "),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P070.009")))]),
                _c("td", [_vm._v(_vm._s(_vm.param.blNo))]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL270.033")))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.list.rstStr))]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("tit.CSBL400.005")))]),
                _vm._v(" "),
                _c("td", [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reqParams.reqCont,
                        expression: "reqParams.reqCont",
                      },
                    ],
                    domProps: { value: _vm.reqParams.reqCont },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.reqParams, "reqCont", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT060P040.002")))]),
                _vm._v(" "),
                _c("td", [
                  _c("p", { staticClass: "mt5" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button sm",
                        on: {
                          click: function ($event) {
                            return _vm.autoArmService()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " [My Information] – " +
                            _vm._s(_vm.$t("msg.SETT060P040.002")) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("msg.SETT060P040.003")))]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "content_box mt10" },
          [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v("2. " + _vm._s(_vm.$t("tit.CSBL400.006"))),
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "tbl_col" }, [
              _vm._m(1),
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                    _vm._v("Size"),
                  ]),
                  _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                    _vm._v("Type"),
                  ]),
                  _c("th", { attrs: { scope: "colgroup", colspan: 6 } }, [
                    _vm._v(_vm._s(_vm.$t("msg.SETT060P040.016"))),
                  ]),
                ]),
                _vm._m(2),
              ]),
              _vm.cntrList
                ? _c(
                    "tbody",
                    _vm._l(_vm.cntrList, function (item, idx) {
                      return _c("tr", { key: idx }, [
                        _c("td", [_vm._v(_vm._s(item.cntrSzCd))]),
                        _c("td", { attrs: { id: "cntrTypCd_" + idx } }, [
                          _vm._v(_vm._s(item.cntrTypCd)),
                        ]),
                        _c("td", { attrs: { id: "dem" + idx } }, [
                          _vm._v(_vm._s(item.demMax)),
                        ]),
                        _c("td", { attrs: { id: "det" + idx } }, [
                          _vm._v(_vm._s(item.detMax)),
                        ]),
                        _c("td", { attrs: { id: "cmb" + idx } }, [
                          _vm._v(_vm._s(item.cmbMax)),
                        ]),
                        _c("td", { attrs: { id: "mor" + idx } }, [
                          _vm._v(_vm._s(item.morMax)),
                        ]),
                        _c("td", { attrs: { id: "str" + idx } }, [
                          _vm._v(_vm._s(item.strMax)),
                        ]),
                        _c("td", { attrs: { id: "ofc" + idx } }, [
                          _vm._v(_vm._s(item.ofcMax)),
                        ]),
                      ])
                    }),
                    0
                  )
                : _c(
                    "tbody",
                    _vm._l(_vm.rstList, function (item, idx) {
                      return _c("tr", { key: idx }, [
                        _c("td", [_vm._v(_vm._s(item.cntrSzCd))]),
                        _c("td", [_vm._v(_vm._s(item.cntrTypCd))]),
                        _c("td"),
                        _c("td"),
                        _c("td"),
                        _c("td"),
                      ])
                    }),
                    0
                  ),
            ]),
            _vm.auth.serviceLang !== "KOR" ? [_vm._m(3)] : _vm._e(),
            _vm.auth.serviceLang === "KOR" ? [_vm._m(4)] : _vm._e(),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v("3. " + _vm._s(_vm.$t("msg.CSBL400.021"))),
            ]),
            _c("div", { staticClass: "desc_list base" }, [
              _c("ul", [
                _c("li", { staticClass: "mt5" }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBL400.013"))),
                ]),
              ]),
            ]),
            _c("br"),
            _c("div", { staticClass: "load", attrs: { id: "load" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  id: "chk1",
                  "true-value": "Y",
                  "false-value": "N",
                },
                domProps: {
                  checked: Array.isArray(_vm.loading)
                    ? _vm._i(_vm.loading, null) > -1
                    : _vm._q(_vm.loading, "Y"),
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.loading,
                        $$el = $event.target,
                        $$c = $$el.checked ? "Y" : "N"
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.loading = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.loading = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.loading = $$c
                      }
                    },
                    function ($event) {
                      $event.preventDefault()
                      return _vm.changeInOut("loading", _vm.loading)
                    },
                  ],
                },
              }),
              _c("label", { attrs: { for: "chk1" } }, [
                _c("span", { staticStyle: { color: "#075bb9" } }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("msg.SETT060P040.004")) + " "),
                  ]),
                ]),
                _vm._v(": " + _vm._s(_vm.polPortEnm) + " "),
              ]),
              _c(
                "table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading === "Y",
                      expression: "loading==='Y'",
                    },
                  ],
                  staticClass: "tbl_col",
                },
                [
                  _vm._m(5),
                  _vm._m(6),
                  _c(
                    "tbody",
                    _vm._l(_vm.outAppRequestList, function (vo, idx) {
                      return _c("tr", { key: "tr_" + idx }, [
                        _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "dem_out_" + vo.cntrTypCd,
                                disabled: vo.dem == 0 || _vm.checkDem,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setOutFreetimeReqList(
                                    vo.outDem,
                                    "01",
                                    "O",
                                    vo.cntrTypCd,
                                    "dem" + idx,
                                    _vm.rstList.polCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.outDem,
                                callback: function ($$v) {
                                  _vm.$set(vo, "outDem", $$v)
                                },
                                expression: "vo.outDem",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "det_out_" + vo.cntrTypCd,
                                disabled: vo.det == 0 || _vm.checkDet,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setOutFreetimeReqList(
                                    vo.outDet,
                                    "02",
                                    "O",
                                    vo.cntrTypCd,
                                    "det" + idx,
                                    _vm.rstList.polCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.outDet,
                                callback: function ($$v) {
                                  _vm.$set(vo, "outDet", $$v)
                                },
                                expression: "vo.outDet",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "cmb_out_" + vo.cntrTypCd,
                                disabled: vo.comb == 0,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setOutFreetimeReqList(
                                    vo.outCmb,
                                    "03",
                                    "O",
                                    vo.cntrTypCd,
                                    "comb" + idx,
                                    _vm.rstList.polCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.outCmb,
                                callback: function ($$v) {
                                  _vm.$set(vo, "outCmb", $$v)
                                },
                                expression: "vo.outCmb",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "mor_out_" + vo.cntrTypCd,
                                disabled: vo.mor == 0 || _vm.checkMor,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setOutFreetimeReqList(
                                    vo.outMor,
                                    "04",
                                    "O",
                                    vo.cntrTypCd,
                                    "mor" + idx,
                                    _vm.rstList.polCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.outMor,
                                callback: function ($$v) {
                                  _vm.$set(vo, "outMor", $$v)
                                },
                                expression: "vo.outMor",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "ofc_out_" + vo.cntrTypCd,
                                disabled: vo.ofc == 0,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setOutFreetimeReqList(
                                    vo.outOfc,
                                    "14",
                                    "O",
                                    vo.cntrTypCd,
                                    "ofc" + idx,
                                    _vm.rstList.polCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.outOfc,
                                callback: function ($$v) {
                                  _vm.$set(vo, "outOfc", $$v)
                                },
                                expression: "vo.outOfc",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
            _c("br"),
            _c("div", { staticClass: "dest", attrs: { id: "dest" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.destination,
                    expression: "destination",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  id: "chk2",
                  "true-value": "Y",
                  "false-value": "N",
                },
                domProps: {
                  checked: Array.isArray(_vm.destination)
                    ? _vm._i(_vm.destination, null) > -1
                    : _vm._q(_vm.destination, "Y"),
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.destination,
                        $$el = $event.target,
                        $$c = $$el.checked ? "Y" : "N"
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.destination = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.destination = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.destination = $$c
                      }
                    },
                    function ($event) {
                      $event.preventDefault()
                      return _vm.changeInOut("destination", _vm.destination)
                    },
                  ],
                },
              }),
              _c("label", { attrs: { for: "chk2" } }, [
                _c("span", { staticStyle: { color: "#075bb9" } }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("msg.SETT060P040.005")) + " "),
                  ]),
                ]),
                _vm._v(": " + _vm._s(_vm.podPortEnm) + " "),
              ]),
              _c(
                "table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.destination === "Y",
                      expression: "destination==='Y'",
                    },
                  ],
                  staticClass: "tbl_col",
                },
                [
                  _vm._m(7),
                  _vm._m(8),
                  _c(
                    "tbody",
                    _vm._l(_vm.inAppRequestList, function (vo, idx) {
                      return _c("tr", { key: "tr_" + idx }, [
                        _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "dem_out_" + vo.cntrTypCd,
                                disabled: vo.dem == 0 || _vm.podCtrCd == "KR",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setInFreetimeReqList(
                                    vo.inDem,
                                    "01",
                                    "I",
                                    vo.cntrTypCd,
                                    "dem" + idx,
                                    _vm.rstList.podCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.inDem,
                                callback: function ($$v) {
                                  _vm.$set(vo, "inDem", $$v)
                                },
                                expression: "vo.inDem",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "det_out_" + vo.cntrTypCd,
                                disabled: vo.det == 0 || _vm.podCtrCd == "KR",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setInFreetimeReqList(
                                    vo.inDet,
                                    "02",
                                    "I",
                                    vo.cntrTypCd,
                                    "det" + idx,
                                    _vm.rstList.podCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.inDet,
                                callback: function ($$v) {
                                  _vm.$set(vo, "inDet", $$v)
                                },
                                expression: "vo.inDet",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "cmb_out_" + vo.cntrTypCd,
                                disabled: vo.comb == 0,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setInFreetimeReqList(
                                    vo.inCmb,
                                    "03",
                                    "I",
                                    vo.cntrTypCd,
                                    "comb" + idx,
                                    _vm.rstList.podCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.inCmb,
                                callback: function ($$v) {
                                  _vm.$set(vo, "inCmb", $$v)
                                },
                                expression: "vo.inCmb",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "mor_out_" + vo.cntrTypCd,
                                disabled: vo.mor == 0,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setInFreetimeReqList(
                                    vo.inMor,
                                    "04",
                                    "I",
                                    vo.cntrTypCd,
                                    "mor" + idx,
                                    _vm.rstList.podCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.inMor,
                                callback: function ($$v) {
                                  _vm.$set(vo, "inMor", $$v)
                                },
                                expression: "vo.inMor",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "ofc_out_" + vo.cntrTypCd,
                                disabled:
                                  vo.ofc == 0 ||
                                  _vm.freetimeExpImpRtnSacYn.rcvOfcImpYn ===
                                    "Y",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setInFreetimeReqList(
                                    vo.inOfc,
                                    "14",
                                    "I",
                                    vo.cntrTypCd,
                                    "ofc" + idx,
                                    _vm.rstList.podCtrCd
                                  )
                                },
                              },
                              model: {
                                value: vo.inOfc,
                                callback: function ($$v) {
                                  _vm.$set(vo, "inOfc", $$v)
                                },
                                expression: "vo.inOfc",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ],
          2
        ),
        _c("p", { staticClass: "mt5 ml20" }, [
          _c(
            "a",
            {
              staticClass: "button sm",
              on: {
                click: function ($event) {
                  return _vm.openPopup("containerCheckPop")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT060P040.006")))]
          ),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  return _vm.insertFreetime()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT060P040.007")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT060P040.008")))]
          ),
        ]),
        _c(
          "div",
          { attrs: { id: "layerPop" } },
          [
            _c(
              "win-layer-pop",
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.popupParams },
                      on: { close: _vm.closePopup, dupOk: _vm.dupCheckPopup },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "18%" } }),
      _c("col", { attrs: { width: "82%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "14%" } }),
      _c("col", { staticStyle: { width: "14%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "border_left", attrs: { scope: "col" } }, [
        _vm._v("DEM"),
      ]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("DET")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("DET (combined)")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("MOR")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("STR")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("OFC")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("br"),
      _vm._v(
        " The numbers indicate the total number of days occurred at the POL/POD regardless of freetime. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("br"),
      _vm._v(
        " 표시된 숫자는 Freetime과 관계없이 선적지/양하지에서 발생한 총 경과일수를 의미합니다. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Type")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("DEM")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("DET")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("DET (combined) ")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("MOR")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("OFC")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("TYPE")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("DEM")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("DET")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("DET (combined) ")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("MOR")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("OFC")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }