<template>
  <div class="popup_wrap" style="width:600px; height:700px">
    <!-- 팝업사이즈 참고 : 600*300  popup_wrap -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.SETT060P040.006') }}</h1>
      <!-- content_box -->
      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col style="width:28%">
            <col style="width:16%">
            <col style="width:16%">
            <col style="width:18%">
            <col style="width:22%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Container No.</th>
              <th scope="col">Size</th>
              <th scope="col">Type</th>
              <th scope="col">Cargo Type</th>
              <th scope="col">Shipper's Van</th>
            </tr>
          </thead>
          <tbody v-if="containerInfoList">
            <tr v-for="(item, idx) in containerInfoList" :key="idx">
              <td> {{ item.cntrNo }}</td>
              <td> {{ item.cntrSzCd }}</td>
              <td> {{ item.cntrTypCd }}</td>
              <td> {{ item.cntrQty }}</td>
              <td> {{ item.rcvCpltYn }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button gray lg" href="#" @click="$emit('close')">{{ $t('common.FILE_UPLOAD.011') }}</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import FreeTimeExtensReq from '@/api/rest/settle/freeTimeExtensReq'

export default {
  name: 'ContainerCheck',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          eiCatCd: '',
          sr: ''
        }
      }
    }
  },
  data: function () {
    return {
      param: {
        blNo: this.parentInfo.blNo,
        eiCatCd: ' ',
        sr: ' '
      },

      list: null,
      containerInfoList: null

    }
  },
  computed: {
    //this.demDetSelectCheck()
  },
  watch: {
  },
  created () {
    //demDetSelectCheck()
  },
  mounted: function () {
    const $vm = this

    //조회
    $vm.searchData()
  },
  methods: {
    searchData: function () {
       console.log('this.param.eiCatCd=' + this.param.eiCatCd)
       console.log('this.param.blNo=' + this.param.blNo)

      // this.param.blNo = 'PUS0568323' //props blNo
      // this.param.eiCatCd = 'O' //props eiCatCd
      this.param.sr = '' //props sr
      FreeTimeExtensReq.findFreetimeCntrNo(this.param).then(response => {
        console.log('response.data=' + JSON.stringify(response.data))

        this.list = response.data // rstList
        this.containerInfoList = this.list.rstList
      }).catch(err => {
        console.log(err)
        //this.contents = 'xxxxxxxxxxxxxxxxxxxxx'
      })
      //console.log('searchData')
    }
  }
}
</script>

<style scoped>
</style>
