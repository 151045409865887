var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "600px", height: "700px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SETT060P040.006"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _vm._m(1),
            _vm.containerInfoList
              ? _c(
                  "tbody",
                  _vm._l(_vm.containerInfoList, function (item, idx) {
                    return _c("tr", { key: idx }, [
                      _c("td", [_vm._v(" " + _vm._s(item.cntrNo))]),
                      _c("td", [_vm._v(" " + _vm._s(item.cntrSzCd))]),
                      _c("td", [_vm._v(" " + _vm._s(item.cntrTypCd))]),
                      _c("td", [_vm._v(" " + _vm._s(item.cntrQty))]),
                      _c("td", [_vm._v(" " + _vm._s(item.rcvCpltYn))]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.FILE_UPLOAD.011")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "28%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "18%" } }),
      _c("col", { staticStyle: { width: "22%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Container No.")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Size")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Type")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Cargo Type")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Shipper's Van")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }