import Send from '../../settle.client.js'

export default {
    freeTimeExtensReqList (param) { // freetime 연장 요청 화면 조회
        return Send({
            // url: 'http://localhost:9008/settle/freetime/request',
            url: '/settle/freetime/request',
            method: 'get',
            params: {
                blNo: param.blNo,
                eiCatCd: param.eiCatCd,
                sr: param.sr
            }
        })
    },
    findFreetimeCntrNo (param) { // freetime 컨테이너 정보 조회
        return Send({
            //url: 'http://localhost:9008/settle/freetime/cntrno',
            url: '/settle/freetime/cntrno',
            method: 'get',
            params: {
                blNo: param.blNo,
                eiCatCd: param.eiCatCd,
                sr: param.sr
            }
        })
    },
    insertRequestFreetime (data) { // freetime 컨테이너 정보 조회
        return Send({
            // url: 'http://localhost:9008/settle/freetime/freetime',
            url: '/settle/freetime/freetime',
            method: 'post',
            data: data
        })
    },
    findFreetimeDupList (param) { // freetime 중복 요청 리스트 조회
        return Send({
            // url: 'http://localhost:9008/settle/freetime/freetimeDup',
            url: '/settle/freetime/freetimeDup',
            method: 'get',
            params: {
                dupStr: param
            }
        })
    },
    findFreetimeApvList (param) { // freetime 승인 요청 리스트 조회
        return Send({
            // url: 'http://localhost:9008/settle/freetime/freetimeApv',
            url: '/settle/freetime/freetimeApv',
            method: 'get',
            params: {
                apvStr: param
            }
        })
    }
}
